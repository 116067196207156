<template>
  <div class="notice container">
    <div class="content-item" v-for="(item,index) of dataList" :key="index">
      <!-- tab选项卡 -->
      <ul class="tabs">
        <li class="tabs-eng active">{{item.moduleName}}</li>
        <!-- 左侧导航 -->
        <div class="nav">
        <el-radio-group size="mini" v-if="index==0" v-model="activeNav" @change="activeNavChange()">
            <el-radio-button
              v-for="v in item.children"
              :key="v.id"
              :label="v.moduleName"
            >
              <el-badge :value="v.num" class="item">{{v.moduleName}}</el-badge>
            </el-radio-button>
          </el-radio-group>
          <el-radio-group size="mini" v-else v-model="activeNav1" @change="activeNavChange1()">
            <el-radio-button
              v-for="v in item.children"
              :key="v.id"
              :label="v.moduleName"
            >
              <el-badge :value="v.num" class="item">{{v.moduleName}}</el-badge>
            </el-radio-button>
          </el-radio-group>
        </div>

        <span  @click="$router.push({ name:'招采信息',query:{isGov:item.sortIndex,type:activeNav} })" class="more">更多
          <i class="el-icon-arrow-right"></i>
        </span>
      </ul>
      <!-- 主体 -->
      <div class="main">
        
        <!-- 右侧列表 -->
        <ul class="list" v-if="index==0">
          <li v-for="v in listData" :key="v.id">
            <div class="list-title" @click="jumpDetail(v)">
              <div class="title text-overflow-ellipsis">{{v.title}}</div>
              <div class="type">{{v.typeName}}</div>
              <div class="type" v-if="v.electronic || v.electronic+ '' == 0" style="margin-left: 10px">{{v.electronic|filterElectronicType}}</div>
            </div>
            <div class="list-time">
              <div class="date">{{v.releaseTime|filterTimeNoHms}}</div>
              <div class="times" v-if="(v.typeName.indexOf('公告') !== -1 ) && v.typeName.indexOf('异常') == -1">
                <div class="dates">招标文件获取开始时间 {{v.docGetBegin|filterTime}}</div>
                <div class="dates">招标文件获取结束时间 {{v.docGetEnd|filterTime}}</div>
                <div class="dates">投标文件递交截止时间 {{v.tenderDocSubmitEnd|filterTime}}</div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="list" v-else>
          <li v-for="v in listData1" :key="v.id">
            <div class="list-title" @click="jumpDetail(v)">
              <div class="title text-overflow-ellipsis">{{v.title}}</div>
              <div class="type">{{v.typeName}}</div>
              <div class="type" v-if="v.electronic || v.electronic+ '' == 0" style="margin-left: 10px">{{v.electronic|filterElectronicType}}</div>
            </div>
            <div class="list-time">
              <div class="date">{{v.releaseTime|filterTimeNoHms}}</div>
              <div class="times" v-if="(v.typeName.indexOf('公告') !== -1) && v.typeName.indexOf('异常') == -1 && v.typeName.indexOf('合同') == -1">
                <div class="dates">招标文件获取开始时间 {{v.docGetBegin|filterTime}}</div>
                <div class="dates">招标文件获取结束时间 {{v.docGetEnd|filterTime}}</div>
                <div class="dates">投标文件递交截止时间 {{v.tenderDocSubmitEnd|filterTime}}</div>
              </div>
              <div v-if="v.typeName.indexOf('合同公告') !== -1" class="dates">公告合同发布时间 {{v.releaseTime|filterTime}}</div>
            </div>
          </li>
        </ul>
      </div>

    </div>

  </div>
</template>

<script>
import indexApi from "@/api/index";

export default {
  created() {
    // 获取导航菜单
    indexApi.findTradeNav().then(res => {
      this.dataList = res.data
      this.engNavList = res.data[0].children;
      this.govNavList = res.data[1].children;
    });
    this.renderList();
    this.renderList1();
  },
  data() {
    return {
      dataList: [],// 列表总数据
      engNavList: [], //工程建设的导航列表
      govNavList: [], //政府采购的导航列表
      listData: [], //右边的内容列表
      listData1: [], //右边的内容列表

      activeTab: 1, //激活的tab页，跟isGov匹配
      activeTab1: 2, //激活的tab页，跟isGov匹配
      activeNav: "全部", //激活的导航，跟type匹配
      activeNav1: "全部"
    };
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    },
    filterTimeNoHms(val) {
      return moment(val).format("YYYY-MM-DD");
    },
  },
  methods: {
    activeNavChange() {
      this.renderList();
    },
    activeNavChange1() {
      this.renderList1();
    },
    // 点击列表的跳转函数（跳到详情页）
    jumpDetail(v) {
      // 招标公告:notice 变更公告:change 补遗通知:addendum 异常公告:exception 结果公示:publicity
      // 结果公示（1评标结果公示 3中标结果公示 4流标公示）
      if (v.type === "notice")
        this.$router.push({
          name: "招采信息详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      if (v.type === "change")
        this.$router.push({
          name: "招采变更信息详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      if ( v.type === "contract") {
        this.$router.push({
          name: "合同公告详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      }
      if (v.type === "addendum")
        this.$router.push({
          name: "招采信息补遗通知详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      if (v.type === "exception")
        this.$router.push({
          name: "招采信息异常公告详情",
          query: { id: v.id, type: v.type, isGov: v.isGov }
        });
      // 结果公示
      if (
        v.type == 1 ||
        v.type == 2 ||
        v.type == 3 ||
        v.type == 4 ||
        v.type == 5
      )
        this.$router.push({
          name: "招采信息结果公示详情",
          query: {
            id: v.id,
            type: v.type,
            isGov: v.isGov,
            correctType: v.correctResultType, //修正类型（1.修正评标结果 2.修正流标结果）
            reviewResult: v.reviewResult //复核结果（1维持原结果 2变更原结果 3本次流标）
          }
        });
    },

    // 渲染相关函数
    async renderList() {
      let { code, data, message } = await indexApi.findTradeList({
        isGov: this.activeTab,
        type: this.activeNav,
        page: 1,
        size: 10,
        sort: "desc",
        sortFiled: "release_time"
      });
      this.listData = data.records;
    },
    async renderList1() {
      let { code, data, message } = await indexApi.findTradeList({
        isGov: this.activeTab1,
        type: this.activeNav1,
        page: 1,
        size: 10,
        sort: "desc",
        sortFiled: "release_time"
      });
      this.listData1 = data.records;
    }
  }
};
</script>

<style lang="less" scoped>
.notice {
  margin-top: 20px;
  margin-bottom: 60px;
  .tabs {
    color: #303030;
    font-size: 18px;
    line-height: 200%;
    background-color: #fff;
    display: flex;
    border-bottom: 1px solid #ECECF1;
    position: relative;
    .more{
      position: absolute;
      color: #898A8D;
      right: 0;
      font-size: 14px;
      top: 2px;
      cursor: pointer;
      &:hover{
        color: #F36E31;
      }
    }
    li {
      text-align: center;
      margin-right: 20px;
      cursor: pointer;
      &.active {
        color: #F36E31;
        font-weight: 600;
        border-bottom: 3px solid #F36E31;
        font-size: 24px;
      }
    }
  }
  .nav {
      color: #20201E;
      .el-radio-group {
        width: 100%;
        .el-radio-button {
            overflow: visible;
            margin-right: 20px;
          /deep/.el-radio-button__inner {
            width: 100%;
            text-align: left;
            overflow: visible;
            border: none;
            font-size: 14px;
            border-radius: 4px;
            .el-badge__content {
              z-index: 9;
              right: -2px;
              top: -8px;
              background-color: #FA5151;
              border-bottom-left-radius: 0;
              border: none;
            }
          }
          // 修改框架的激活背景色
          /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
            // background-color:transparent;
            // color: #F36E31;
            // box-shadow: none;
            // border: none;
            border-radius: 0;
          }
        }
      }
      // 查看更多按钮
      .view-more {
        color: #3660d9;
        width: 120px;
        text-align: center;
        line-height: 30px;
        margin: 0 auto;
        border: 1px solid #3660d9;
        border-radius: 5px;
        cursor: pointer;
        .text {
          margin-right: 10px;
        }
      }
    }
  // main
  .main {
    // height: 460px;
    display: flex;
    flex-direction: column;
    // nav


    // list
    .list {
      flex: 1;
      padding-bottom: 10px;
      // padding: 10px;
      li::before{
        content: '';
        width: 6px;
        height: 6px;
        background: #F36E31;
        border-radius: 50%;
        margin-top: 8px;
        margin-right: 10px;
        position: absolute;
      }
      li {
        display: flex;
        flex-direction: column;
        line-height: 20px;
        // margin-bottom: 16px;
        padding: 10px;
        border-bottom: 1px dashed #ECECF1;
        padding: 20px 0 20px 20px;
        &:hover{
          background-color: #fff7f3;
        }
        .list-title{
          display: flex;
          .title {
            max-width: 80%;
          }
        }
        .type {
          padding: 0 12px;
          line-height: 20px;
          font-size: 12px;
          color: #FF5C00;
          text-align: center;
          border: 1px solid #FF5C00;
          border-radius: 4px;
        }
        .title {
          cursor: pointer;
          margin-right: 20px;
          margin-left: 10px;
          &:hover{
            color: #F36E31;
          }
        }
        .list-time{
          display: flex;
          margin-top: 10px;
          align-items: center;
          .date {
            color: #a3a3a3;
            font-size: 14px;
          }
          .times{
            display: flex;
            align-items: center;
          }
          .dates{
            color: #a3a3a3;
            font-size: 14px;
            margin: 0 10px;
            line-height: 24px;
            padding: 0 14px;
            border-radius: 12px;
            background: #ECECF1;
          }
        }
      }
    }
  }
}
</style>