/* 
  通知公告相关接口
*/

import req from '@/utils/request' //引入暴露出来的请求函数

export default {
  // 获取通知公告列表
  findInformNoticeList: params => req.get('/api/noticeInfo/list/all', params),
  // 获取通知公告详情
  findInformNoticeDetail: params => req.get('/api/noticeInfo/detail', params),
  

}
