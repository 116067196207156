<template>
  <div class="notice-detail container"> 
    <!-- 文章 -->
    <article>
      <h1 v-html="detailHtml.title" class="article-title"></h1>
      <div class="article-time">{{ detailHtml.modifyTime|filterTime }}</div>
      <div v-html="detailHtml.content" class="article-content"></div>
    </article>

    <!-- 附件列表 -->
    <div class="file-list">
      <h3 class="file-title">附件列表</h3>
      <a
        v-for="(v,i) in fileList"
        :key="i"
        :href="baseURL+v"
        target="_blank"
      >{{ v.substring( v.lastIndexOf('/')+1 ) }}</a>
    </div>

  </div>
</template>

<script>
import informNoticeApi from "@/api/informNotice";
import { mapState } from "vuex";
import Notice from '../../../components/Index/Notice.vue';

export default {
  async created() {
    // 获取通知公告id
    this.informNoticeId = this.$route.query.informNoticeId;
    // 根据通知公告id来获取详情
    let { code, data, message } = await informNoticeApi.findInformNoticeDetail({
      id: this.informNoticeId
    });
    this.detailHtml = data;
    this.fileList = JSON.parse(this.detailHtml.attachFilePath);
  },
  data() {
    return {
      informNoticeId: 0,
      detailHtml: {},
      bidList: [
        {
          bidNum: "GC53000020210",
          bidName: "云南省永昌监狱建设项（一期）EPC总承包招标公告",
          leibie: "施工",
          startTime: "2021-02-22 12:34",
          endTime: "2021-02-22 12:34",
          bidEndTime: "2021-02-22 12:34"
        }
      ],
      // 附件相关参数
      fileList: []
    };
  },
  computed: {
    ...mapState(["baseURL"])
  },
  filters: {
    filterTime(val) {
      return moment(val).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  methods: {
    // 附件相关函数
    handlePreview(file) {
      window.open(file.url);
    }
  }
};
</script>

<style lang="less" scoped>
.notice-detail {
  // 文章
  article {
    padding: 30px;
    // 这里为了防止覆盖下面html代码块的样式，所以取个独特的类名来写样式
    .article-title {
      font-size: 26px;
      text-align: center;
    }
    .article-time {
      color: #979797;
      text-align: center;
      padding: 20px 0;
      border-bottom: 1px dashed #dcdcdc;
    }
    .article-content {
      padding: 10px 0;
    }
  }

  // 附件列表
  .file-list {
    padding-left: 30px;
    margin-bottom: 50px;
    .file-title {
      font-size: 20px;
    }
    a {
      color: #3660d9;
      display: block;
      line-height: 26px;
    }
  }
}
</style>