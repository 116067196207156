/* 
  首页相关接口
*/

import req from '@/utils/request' //引入暴露出来的请求函数

export default {
  // 获取招采信息导航
  findTradeNav: params => req.get('/api/homePage/tranNavigation', params),
  // 获取招采信息列表
  findTradeList: params => req.get('/api/homePage/findTransactionList', params),

  // 获取 (1:通知公告、2:最新动态、3:政策法规、4:下载专区、5:服务指南) 列表
  findInformOrNewsList: params => req.get('/api/homePage/list/all', params),

  // 获取轮播图
  findBanner: params => req.get('/api/homePage/carousel', params),

  // 统计页面访问次数
  getVisitNum: params => req.get('/api/homePage/platformStatistics', params),

  // 获取开标日程（招标公告（变更公告）、采购公告（变更公告））
  getBidSchedule: params => req.get('/api/homePage/bidSchedule', params),
  
  // 图片点击记录次数
  imageClick: params => req.get('/api/homePage/image/click', params),

  // 获取访问数据量
  getStatistics: params => req.get('/api/homePage/platformStatistics', params),

  getHot: params => req.get('/api/homePage/hot', params),

  // 快速招采
  // 获取角标
  getSubscript: params => req.get('/api/fast/subscript', params),
  // 获取全部
  getAllFast: params => req.get('/api/fast/allFastTransaction', params),
  // 公告列表
  getFastNotice: params => req.get('/api/fast/notice/page', params),
  getFastPublicity: params => req.get('/api/fast/publicity/page', params),
  getNoticeDetail: params => req.get('/api/fast/notice/detail', params),
  getPublicityDetail: params => req.get('/api/fast/publicity/detail', params),
  
}
